//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'

export const useDairyTypesStore = defineStore('DairyTypesStore', {
  state: () => ({
    dairyTypes: [] as any[],
    dairyTypesIncVirtual: [] as any[]
  }),
  actions: {
    async fetchDairyTypes(): Promise<void> {
      if (this.dairyTypes.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/dairyTypes')
          this.dairyTypes = result.data
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchDairyTypesIncVirtual(): Promise<void> {
      if (this.dairyTypesIncVirtual.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/dairyTypes?includeVirtual=true')
          this.dairyTypesIncVirtual = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
